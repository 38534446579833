<script setup lang="ts">
import type { PdpEcommerceBlockColorSelectorProps } from './PdpEcommerceBlockColorSelector.props'
import type { ColorOption } from '@design-system/components/Pdp/PdpColorSelector.props.ts'

const { openDialog } = useDialog()

const selectedOption = ref<ColorOption | null>(null)

const emit = defineEmits<{
  (e: 'update:selectedOption', option: ColorOption): void
}>()

const handleColorSelection = (option: ColorOption) => {
  selectedOption.value = option
  emit('update:selectedOption', option)
}
const props = defineProps<PdpEcommerceBlockColorSelectorProps>()

const displayedColor = computed(() => {
  return formatProductName(
    selectedOption?.value?.color || props?.selectedColor || ''
  )
})

const hasExtColors = computed(
  () => props.extColors && props.extColors?.length > 0
)
</script>

<template>
  <div>
    <button
      class="flex w-full items-center justify-between"
      :disabled="options.length <= 1 && extColors?.length === 0"
      aria-haspopup="dialog"
      @click="openDialog(sidebarId)"
    >
      <div class="gap-primitives-1 flex items-center">
        <span class="text-medium-6">{{ $ts('pdp.colorSelect.color') }}</span>
        <span
          v-if="hasExtColors && colorHex"
          class="mx-1 h-5 w-5"
          :style="{ backgroundColor: `#${colorHex}` }"
        />
        <span v-if="displayedColor" class="text-light-6">{{
          displayedColor
        }}</span>
      </div>
      <div
        v-if="options.length > 1 || (extColors && extColors.length > 1)"
        class="gap-primitives-2 flex items-center"
      >
        <span class="text-medium-6">{{ `${options.length} ` }}</span>
        <span class="text-light-6">{{ $ts('pdp.colorSelect.colors') }}</span>
        <DefaultIconsChevronRight aria-hidden="true" />
      </div>
    </button>
    <PdpColorSelector
      v-if="!hideSidebarComponent"
      :id="sidebarId"
      :sku-code="skuCode"
      :options="options"
      :selected-color="selectedColor"
      :ext-colors="extColors"
      :has-back-button="hasBackButton"
      @update:selected-color="(e: ColorOption) => handleColorSelection(e)"
    />
  </div>
</template>
