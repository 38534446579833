export const useFitFinder = async (sizeGridCode: string) => {
  const nuxtApp = useNuxtApp()

  const { getSizesByCode, countries, macroCategories } =
    await useSizeRetrieveData()

  const { data: sizeGridData } = await nuxtApp.runWithContext(() =>
    getSizesByCode(sizeGridCode)
  )

  const productCategory = computed(() => {
    if (!sizeGridCode) return null
    if (!macroCategories) return null

    for (const category of macroCategories) {
      const subCategories = category?.subCategories
      if (!subCategories) continue
      for (const subCategory of subCategories) {
        const sizes = subCategory?.sizes
        if (!sizes) continue
        for (const size of sizes) {
          if (size === sizeGridCode) {
            return subCategory
          }
        }
      }
    }
    return null
  })

  return {
    sizeGridData,
    productCategory,
    countries,
  }
}
