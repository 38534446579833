import type { ProductSchema } from '#build/armani/product-schema'
export const checkMedia = (item: ProductSchema) => {
  // process.dev
  // console.warn(
  //   'Deprecation notice: you are still checking for images || assets inside algolia. Remove this check when unnecessary'
  // )
  return item?.images || item?.assets
}

export const getFirstMediaByPriority = (
  medias: ProductSchema['images'] | ProductSchema['assets']
) => {
  if (!medias) return ''
  // Sort the medias array based on their priority values in ascending order
  medias.sort((a, b) => (a.priority || 0) - (b.priority || 0))

  // Return the URL of the first media item
  return medias.length > 0
    ? ((medias[0] as { URL?: string }).URL ??
        (medias[0] as { ImageURL?: string }).ImageURL)
    : ''
}
