import type { SizeData } from '@design-system/components/Pdp/PdpFitFinder.props'

export const useSizeSelector = (
  algoliaSizes: ComputedRef<{ sku: string; size: string; disabled: boolean }[]>,
  isExtSizes: boolean = false,
  sizesData: SizeData[] = []
) => {
  const { selectedCountry, sizeCountries } = useSizeCountry()

  const sizes = computed(() => {
    const generateLocalizedSizes = (country: string) => {
      return algoliaSizes.value.map(algoliaSize => {
        const sizeData = sizesData.find(
          sizeData => sizeData.TGL_COD === algoliaSize.size
        )
        const localizedSize = sizeData
          ? (sizeData[`${country}_ALS`] ?? sizeData['TGL_COD'])
          : algoliaSize.size
        return {
          ...algoliaSize,
          size: localizedSize as string,
          sizeCode: sizeData ? sizeData['TGL_COD'] : algoliaSize.size,
        }
      })
    }

    return Object.fromEntries(
      sizeCountries.map(country => [
        country,
        sizesData?.length > 0
          ? generateLocalizedSizes(country)
          : algoliaSizes.value,
      ])
    )
  })

  // preselect onesize if available
  const getInitialSizeIndex = () => {
    const firstSize = algoliaSizes.value?.[0]
    return !firstSize?.disabled &&
      (firstSize?.size === 'Onesize' || firstSize?.size === 'TU')
      ? 0
      : null
  }

  const selectedSizeIndex = ref<number | null>(getInitialSizeIndex())

  const selectedSize = computed(() => {
    if (isExtSizes) selectedSizeIndex.value = 0
    return selectedSizeIndex.value !== null
      ? sizes.value[selectedCountry.value][selectedSizeIndex.value]
      : null
  })

  return {
    sizes,
    selectedCountry,
    selectedSizeIndex,
    selectedSize,
  }
}
