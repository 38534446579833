export const useSizebayEvents = () => {
  const emitSizebayProductViewEvent = (
    productId?: string,
    productTitle?: string
  ) => {
    const productViewEvent = new CustomEvent('product_view', {
      detail: {
        productId: productId ?? '',
        productTitle: productTitle ?? '',
      },
    })
    window.dispatchEvent(productViewEvent)
  }
  return {
    emitSizebayProductViewEvent,
  }
}
