export const formatProductName = (productName: string) => {
  if (typeof productName !== 'string') {
    return ''
  }

  const lowercaseProductName = productName.toLowerCase()
  return (
    lowercaseProductName.charAt(0).toUpperCase() + lowercaseProductName.slice(1)
  )
}
