<script setup lang="ts">
import type { PdpSizeGuideTableProps } from './PdpSizeGuideTable.props'

const props = defineProps<PdpSizeGuideTableProps>()
</script>

<template>
  <div class="table-container">
    <table class="w-full table-auto">
      <thead>
        <tr>
          <th
            v-for="(header, index) in props.headers"
            :key="header"
            class="text-book-6"
            :class="[
              index > 1 ? 'bg-background-tertiary' : 'bg-background-table-head',
            ]"
          >
            {{ $ts(header) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :class="[
            rowIndex % 2 !== 0
              ? 'bg-background-tertiary'
              : 'bg-background-secondary',
          ]"
        >
          <td
            v-for="(col, colIndex) in row"
            :key="colIndex"
            class="text-book-6"
            :class="{
              'bg-background-table-head': colIndex <= 1,
            }"
          >
            {{ col }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="scss">
.table-container {
  overflow-x: auto;
  margin-bottom: 40px;
}

.table-auto {
  overflow: auto;
  border-collapse: collapse;
}

.table-auto th,
.table-auto td {
  padding: 8px;
  min-width: 100px;

  @apply border-stroke-table border;
}

.table-auto th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}
</style>
