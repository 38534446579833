<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { PdpFitFinderProps } from '@design-system/components/Pdp/PdpFitFinder.props'

const props = defineProps<PdpFitFinderProps>()
const selectedTabIndex = ref(0)
const { getLocale, ts } = useI18n()
const locale = getLocale()

const unitOfMeasureOptions = [
  {
    label: 'CM',
    value: 'CM',
  },
  {
    label: 'INCH',
    value: 'INCH',
  },
]

const countriesOptions = computed(() =>
  props.countries.map((ctr: any) => ({ label: ctr.label, value: ctr.key }))
)

const checkCountryActive = (currentCountry: string) => {
  const countryToCheck =
    currentCountry === 'GB'
      ? 'UK'
      : currentCountry === 'FR'
        ? 'FRA'
        : currentCountry
  return (
    countriesOptions.value.find(country => country.value === countryToCheck)
      ?.value ?? 'US'
  )
}

const formSelected = ref({
  region: checkCountryActive(locale.split('-')[1]?.toUpperCase()),
  uom: 'CM',
})

const tabs = computed(() => {
  // const howToMeasure = props.category?.howToMeasure
  const productTab: Array<{
    id: string
    label: string
    text: string
    title: string
    sideSlideDescription?: string
    urlImg?: string
    blocks?: {
      _meta: {
        schema: string
        name: string
      }
      id: string
      title: string
      text: string
    }[]
  }> = [
    {
      id: 'sg',
      label: ts('pdp.fitFinder.tabs.sizeGuide.label'),
      text: ts('pdp.fitFinder.tabs.sizeGuide.text'),
      title: ts('pdp.fitFinder.tabs.sizeGuide.title'),
      sideSlideDescription: props.sideSlideDescription,
    },
  ]

  // if (howToMeasure) {
  //   productTab.push({
  //     id: 'htm',
  //     label: howToMeasure?._meta.name ?? ts('pdp.sizeSelector.howToMeasure'),
  //     text: howToMeasure?.caption ?? '',
  //     urlImg: howToMeasure?.image.media.srcImage.secure_url ?? '',
  //     title: howToMeasure?.title ?? '',
  //     blocks: howToMeasure?.listOfContentItems,
  //   })
  // }

  return productTab
})

const headers = computed(() => {
  if (!props.sizesDetails || !props.sizesDetails[0].Size_Guide) return []

  const sizeGuide = props.sizesDetails[0].Size_Guide!

  const headers = [
    'GA Tag',
    formSelected.value.region,
    ...sizeGuide.map(
      sizeGuide => `pdp.fitFinder.tabs.sizeGuide.fit.${sizeGuide.label}`
    ),
  ]

  return headers
})

const rows = computed(() => {
  const regionAlias = `${formSelected.value.region}_ALS`

  const rows =
    props.sizesDetails?.map(sizeDetail => {
      const TGL_COD = sizeDetail.TGL_COD ? sizeDetail.TGL_COD : '-'

      const sizeDetailsForRegion = sizeDetail[regionAlias]
        ? (sizeDetail[regionAlias] as string) // !!!
        : '-'

      const sizeGuide = sizeDetail.Size_Guide!
      const sizeGuideMeasures = sizeGuide.map(sizeGuide => {
        if (formSelected.value.uom === 'CM' && sizeGuide.cm) {
          return sizeGuide.cm
        } else if (sizeGuide.inch) {
          return sizeGuide.inch
        } else {
          return '-'
        }
      })
      const row = [TGL_COD, sizeDetailsForRegion, ...sizeGuideMeasures]

      return row
    }) ?? []

  return rows
})

const { slider, handleShortcutClick, updateSliderPosition } =
  useSliderTransition(selectedTabIndex.value, false)

onMounted(() => {
  updateSliderPosition(selectedTabIndex.value)
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

const handleResize = () => {
  updateSliderPosition(selectedTabIndex.value)
}
</script>

<template>
  <div>
    
<FormKitLazyProvider config-file="true">
<UtilsTabs
      v-model="selectedTabIndex"
      class-buttons-container="flex space-x-[-1px] slider-tab"
      :tabs="tabs"
    >
      <template #button="{ tab, selected, index }">
        <UITabElement
          class="shortcut-element flex-1"
          :class="{ 'z-10': selected }"
          :shortcut-index="index"
          :selected="selected"
          @click="handleShortcutClick(index)"
        >
          {{ $ts(tab.label) }}
        </UITabElement>
      </template>
      <template #tabs-sufix>
        <div ref="slider" class="slider-tab-border" />
      </template>
      <template #panel="{ tab }">
        <div v-if="tab.id === 'htm'">
          <div class="pt-11" data-testid="how-to-measure-tab">
            <UtilsMarkdown class="text-book-6" :content="tab.text" />
            <div class="container pt-11">
              <div class="flex flex-col md:flex-row lg:flex-col">
                <div class="md:w-1/2 md:pe-6 lg:w-full lg:pe-0">
                  <NuxtPicture :src="tab.urlImg" alt="" />
                </div>
                <div class="pt-0 md:w-1/2 lg:w-full lg:pt-11">
                  <div class="text-book-4 pb-6">{{ tab.title }}</div>
                  <div v-for="block in tab.blocks" :key="block.id" class="pt-5">
                    <p class="text-medium-5">{{ block.title }}</p>
                    <div class="text-book-6">{{ block.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="tab.id === 'sg'">
          <div class="pt-11" data-testid="size-guide-tab">
            <UtilsMarkdown class="text-book-4" :content="tab.text" />
            <div class="container pb-11 pt-6">
              <FormKit v-model="formSelected" type="form" :actions="false">
                <div class="flex flex-col lg:flex-row lg:justify-between">
                  <InputsSelect
                    id="region"
                    :classes="{ outer: 'lg:mr-12 lg:w-1/2' }"
                    :label="$ts('pdp.fitFinder.tabs.sizeGuide.region')"
                    name="region"
                    :options="countriesOptions"
                  />
                  <InputsSelect
                    id="uom"
                    :classes="{ outer: 'lg:w-1/2' }"
                    :label="$ts('pdp.fitFinder.tabs.sizeGuide.units')"
                    name="uom"
                    :options="unitOfMeasureOptions"
                  />
                </div>
              </FormKit>
            </div>
            <div class="pb-10">
              <UtilsMarkdown class="text-medium-6" :content="tab.title" />
              <UtilsMarkdown
                class="text-light-6"
                :content="tab.sideSlideDescription as string"
              />
            </div>
            <PdpSizeGuideTable :headers="headers" :rows="rows" />
          </div>
        </div>
        <div v-else>
          {{ $ts(tab.label) }}
        </div>
      </template>
    </UtilsTabs>
</FormKitLazyProvider>

  </div>
</template>
